import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
//import Photos from 'components/Photos';

const PhotosPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Photos" />
      {/* <Photos /> */}
    </Layout>
  );
};

export default PhotosPage;

/*
  - uncomment in Main Nav
  - uncomment createPages in gatsby-node
*/
